import { Utils } from "@/shared/utils/Utils";
import Vue from "vue";
import { Component, Inject, Watch } from "vue-property-decorator";
import { MastersApiService } from "@/shared/services/mastersApi.service";

@Component({
  name: "Doctors",
  components: {},
  computed: {},
  filters: {
    currency(value: any) {
      if (!value) return "";
      const formatter = new Intl.NumberFormat("es-CO", {
        style: "currency",
        currency: "COP",
        minimumFractionDigits: 0,
      });
      return formatter.format(value);
    },
  },
})
export default class Doctors extends Vue {
  @Inject() mastersApiService!: MastersApiService;

  listDoctors = [];
  listDoctorsFiltered = [];
  listBeneficiarios = [];
  doctorSelected = null;
  showModalBeneficiarios = false;
  filterDoctor = "";
  loading = false;

  async mounted() {
    await this.getDoctors();
  }

  @Watch("filterDoctor")
  onChangeFilterDoctor() {
    if (!this.filterDoctor) {
      this.listDoctorsFiltered = this.listDoctors;
      return;
    }
    const temp = this.listDoctors.filter((e: any) => {
      if (e.nombre.toLowerCase().includes(this.filterDoctor.toLowerCase())) {
        return true;
      }
      if (e.id.toLowerCase().includes(this.filterDoctor.toLowerCase())) {
        return true;
      }
      return false;
    });
    Vue.set(this, "listDoctorsFiltered", temp);
  }

  async getDoctors() {
    this.loading = true;
    this.listDoctors = await this.mastersApiService.getMedicos();
    this.listDoctorsFiltered = this.listDoctors;
    this.loading = false;
  }

  async showBeneficiarios(item: any) {
    this.doctorSelected = item.id;
    this.listBeneficiarios = await this.mastersApiService.getBeneficiarios(
      item.id
    );
    this.showModalBeneficiarios = true;
  }
}
