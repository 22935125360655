import apiClient from "../Http/Http.service";

export class PatientService {
  async getPatient(document: string): Promise<any[]> {
    const response = await apiClient.get(`/patients/${document}`);
    return response.data;
  }

  async updatePatient(data: any): Promise<any[]> {
    const response = await apiClient.patch(
      `/patients/${data.documento}${data.tipoIdentificacion}`,
      {
        phone: data.telefono,
        mobile: data.celular,
        email: data.email,
      }
    );
    return response.data;
  }

  async createPatient(
    data: any
  ): Promise<{ error?: boolean; message: string }> {
    const response = await apiClient.post(`/patients`, data);
    return response.data;
  }
}
