import apiClient from "../Http/Http.service";

export class MastersApiService {
  async getServices() {
    const response = await apiClient.get("/masters/services");
    return response.data;
  }

  async getAppointmentTypes(programId: string) {
    const response = await apiClient.get(
      `/masters/appointment-types?program=${programId}`
    );
    return response.data;
  }

  async getPrograms() {
    const response = await apiClient.get(`/masters/programs`);
    return response.data;
  }

  async getDoctors(programId: string, appointmentTypeId: string) {
    const response = await apiClient.get(
      `/masters/doctors?program=${programId}&appointmentType=${appointmentTypeId}`
    );
    return response.data;
  }

  async getMedicos() {
    const response = await apiClient.get(`/masters/medicos`);
    return response.data;
  }

  async getCustomers() {
    const response = await apiClient.get(`/masters/clientes`);
    return response.data;
  }

  async getProcedures(clienteId: string, serviceId: string) {
    const response = await apiClient.get(
      `/masters/tarifas?cliente=${clienteId}&servicio=${serviceId}`
    );
    return response.data;
  }

  async getBeneficiarios(doctorId: string) {
    const response = await apiClient.get(
      `/masters/beneficiarios?medico=${doctorId}`
    );
    return response.data;
  }
}
