import { render, staticRenderFns } from "./Create.vue?vue&type=template&id=03e63f4f"
import script from "./Create.ts?vue&type=script&lang=js&external"
export * from "./Create.ts?vue&type=script&lang=js&external"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports