import { render, staticRenderFns } from "./ServiceProvider.vue?vue&type=template&id=2b9916b5&scoped=true"
import script from "./ServiceProvider.ts?vue&type=script&lang=js&external"
export * from "./ServiceProvider.ts?vue&type=script&lang=js&external"
import style0 from "./ServiceProvider.vue?vue&type=style&index=0&id=2b9916b5&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2b9916b5",
  null
  
)

export default component.exports