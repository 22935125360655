import { render, staticRenderFns } from "./DetailEvent.vue?vue&type=template&id=390fe758&scoped=true"
import script from "./DetailEvent.ts?vue&type=script&lang=js&external"
export * from "./DetailEvent.ts?vue&type=script&lang=js&external"
import style0 from "./DetailEvent.vue?vue&type=style&index=0&id=390fe758&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "390fe758",
  null
  
)

export default component.exports