import ModalTemplate from "@/components/ModalTemplate/ModalTemplate";
import { AppointmentsService } from "@/shared/services/appointments.service";
import { MastersApiService } from "@/shared/services/mastersApi.service";
import { PatientService } from "@/shared/services/patientService";
import { Utils } from "@/shared/utils/Utils";
import store from "@/store";
import Vue from "vue";
import { Component, Inject, Prop, Watch } from "vue-property-decorator";

@Component({
  name: "DetailEvent",
  components: {
    ModalTemplate,
  },
  computed: {},
})
export default class DetailEvent extends Vue {
  @Inject() patientService!: PatientService;
  @Inject() mastersApiService!: MastersApiService;
  @Inject() appointmentsService!: AppointmentsService;

  @Prop() eventData!: CalendarEvent;

  appointmentToAssign = {};
  documentPatient: string = "";
  errorMessages: string[] = [];
  patientData: Patient | null = null;

  listServices = [];
  listProcedures = [];
  listCustomers = [];

  appointmentData: AppointmentData | undefined;

  customerSelected = null;
  serviceSelected = null;
  procedureSelected = null;

  errorMessage = null;

  editingPatient = false;
  messageUpdated = "";
  formValid = false;

  newEmail: null | string = null;
  newTelefono: null | string = null;
  newCelular: null | string = null;

  canCreatePatient = false;

  rules = {
    required: (value: any) => !!value || "Required.",
    email: (value: any) => {
      const pattern =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return pattern.test(value) || "El correo ingresado no es válido";
    },
    number: (value: any) => {
      const pattern = /^\d+$/;
      return pattern.test(value) || "Sólo se permiten números";
    },
  };

  get canSave() {
    console.log(
      this.customerSelected &&
        this.serviceSelected &&
        this.procedureSelected &&
        this.patientData
    );
    return (
      this.customerSelected &&
      this.serviceSelected &&
      this.procedureSelected &&
      this.patientData
    );
  }

  get dateFormated() {
    return Utils.formatDateCalendar(this.eventData.start);
  }

  async mounted() {
    await this.blockSpace();
  }

  @Watch("customerSelected")
  async onCustomerChange() {
    await this.getProcedures();
  }

  @Watch("serviceSelected")
  async onServiceChange() {
    await this.getProcedures();
  }

  @Watch("documentPatient")
  async onChangePatientDocument(input: any) {
    if (input.key != "Enter") {
      this.errorMessages = [];
      this.patientData = null;
      return;
    }
    this.getPatientData();
  }

  async blockSpace() {
    const appointmentToLock = {
      programId: this.eventData.programId,
      doctorCode: this.eventData.doctor,
      registerDate: Utils.formatOnlyDateForSchedule(this.eventData.start),
      registerHour: Utils.formatOnlyHourForSchedule(this.eventData.start),
      block: 1,
    };
    await this.appointmentsService.blockAppointment(appointmentToLock);
  }

  async unlockSpace() {
    const appointmentToLock = {
      programId: this.eventData.programId,
      doctorCode: this.eventData.doctor,
      registerDate: Utils.formatOnlyDateForSchedule(this.eventData.start),
      registerHour: Utils.formatOnlyHourForSchedule(this.eventData.start),
      block: 0,
    };
    await this.appointmentsService.unlockAppointment(appointmentToLock);
  }

  async getPatientData() {
    this.errorMessages = [];
    this.patientData = null;
    this.canCreatePatient = false;
    const patientInfo = await this.patientService.getPatient(
      this.documentPatient
    );
    if (patientInfo.length == 0) {
      this.errorMessages.push(
        "No se encontró un afiliado con ese documento de identidad."
      );
      this.canCreatePatient = true;
      return;
    }

    this.patientData = {
      documento: patientInfo[0].Identificacion,
      tipoIdentificacion: patientInfo[0].TipoDocumento,
      pNombre: patientInfo[0].PriNombre,
      sNombre: patientInfo[0].SdoNombre,
      pApellido: patientInfo[0].PriApellido,
      sApellido: patientInfo[0].SdoApellido,
      telefono: patientInfo[0].Telefono,
      celular: patientInfo[0].Celular == "NULL" ? "" : patientInfo[0].Celular,
      email: patientInfo[0].CorreoE,
      fechaNacimiento: patientInfo[0].FechaNac,
      tipoAfiliacion: patientInfo[0].TipoAfiliacion,
    };

    await this.getCustomers();
    await this.getServices();
  }

  async closeModal() {
    await this.unlockSpace();
    this.$emit("closeModal");
  }

  async getServices() {
    this.listServices = await this.mastersApiService.getServices();
  }

  async getCustomers() {
    this.listCustomers = await this.mastersApiService.getCustomers();
  }

  async getProcedures() {
    if (!this.customerSelected || !this.serviceSelected) {
      return;
    }
    this.listProcedures = await this.mastersApiService.getProcedures(
      this.customerSelected,
      this.serviceSelected
    );
  }

  async scheduleAppointment() {
    try {
      this.errorMessage = null;
      const procedure: any = this.listProcedures.find(
        (e: any) => e.id == this.procedureSelected
      );
      const customer: any = this.listCustomers.find(
        (e: any) => e.id == this.customerSelected
      );
      this.appointmentData = {
        doctorCode: this.eventData.doctor ?? "",
        identification: this.patientData?.documento ?? "",
        identificationType: this.patientData?.tipoIdentificacion ?? "",
        programId: this.eventData.programId ?? "",
        epsCode: customer ? customer.id : "",
        epsName: customer ? customer.name : "",
        procedureCode: procedure ? procedure.id : "",
        procedureName: procedure ? procedure.name : "",
        registerDate: Utils.formatOnlyDateForSchedule(this.eventData.start),
        registerHour: Utils.formatOnlyHourForSchedule(this.eventData.start),
        userCreated: store.getters.userLoggedName,
      };
      const response = await this.appointmentsService.scheduleAppointment(
        this.appointmentData
      );
      if (response.appointmentData) {
        this.$emit("closeModal", response.appointmentData);
      } else {
        this.errorMessage = response.message;
      }
    } catch (error: any) {
      // this.errorMessage = error;
    }
  }

  showEditPatient() {
    this.newTelefono = this.patientData?.telefono ?? null;
    this.newCelular = this.patientData?.celular ?? null;
    this.newEmail = this.patientData?.email ?? null;
    this.editingPatient = true;
  }

  async updatePatient() {
    if (!this.patientData) {
      return;
    }
    const newPatientData = {
      ...this.patientData,
      telefono: this.newTelefono,
      celular: this.newCelular,
      email: this.newEmail,
    };
    const response: any = await this.patientService.updatePatient(
      newPatientData
    );
    this.messageUpdated = response.message;
    this.editingPatient = false;
    setTimeout(() => {
      this.messageUpdated = "";
      this.getPatientData();
    }, 1500);
  }

  async cancelEditPatient() {
    this.newTelefono = null;
    this.newCelular = null;
    this.newEmail = null;
    this.editingPatient = false;
  }

  async redirectCreatePatient() {
    const url = await this.$router.resolve({
      name: "CreatePatient", // O puedes usar 'path'
    }).href;
    window.open(url, "_blank");
  }
}
