import Vue from "vue";
import Vuetify from "vuetify/lib/framework";

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    themes: {
      light: {
        darkBackground: "#1A2035",
        grayBackground: "#F5F7FD",
        greenDark: "#059669",
        greenLight: "#AAF4D1",
        blueDark: "#2563EB",
        blueLight: "#BFDBFE",
        purpleDark: "#4F46E5",
        purpleLight: "#C9D3FE",
        redDark: "#DC2626",
        redLight: "#FECBCB",
        orangeDark: "#D97706",
        orangeLight: "#FDE68D",
        pinkDark: "#DB2777",
        pinkLight: "#FBD1E9",
      },
    },
  },
  icons: {
    iconfont: "mdiSvg", // 'mdi' || 'mdiSvg' || 'md' || 'fa' || 'fa4' || 'faSvg'
  },
});
