import router from "@/router";
import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    userId: "",
    email: "",
    refreshToken: "",
    displayName: "",
    alertMessage: {
      text: "",
      type: "",
    },
  },
  getters: {
    getIsLogged: (state) => state.userId ?? false,
    alertMessage: (state) => state.alertMessage,
    showAlert: (state) => {
      if (state.alertMessage.type) {
        return true;
      }
      return false;
    },
    userLoggedName: (state) => state.displayName,
  },
  mutations: {
    setUserLogged(state, payload) {
      state.userId = payload.uid;
      // state.email = payload.email;
      // state.refreshToken = payload.refreshToken;
      state.displayName = payload.displayName;
      console.log("despues", payload);
      localStorage.setItem("userLogged", JSON.stringify(payload));
    },
    setCloseSession(state) {
      state.userId = "";
      // state.email = "";
      // state.refreshToken = "";
      state.displayName = "";
      localStorage.removeItem("userLogged");
    },
    setAlert(state, payload) {
      console.log("new", payload);
      state.alertMessage = {
        text: payload ? payload.text : "",
        type: payload ? payload.type : "",
      };
    },
  },
  actions: {
    setUserLogged({ commit }, payload) {
      commit("setUserLogged", payload);
    },
    setCloseSession({ commit }) {
      commit("setCloseSession");
      if (router.currentRoute.path != "/login") {
        router.push("/login");
      }
    },
    setAlert({ commit }, payload) {
      console.log("pay", payload);
      commit("setAlert", payload);
      setTimeout(() => {
        commit("setAlert", null);
      }, 3000);
    },
  },
  modules: {},
});
