/* eslint-disable */
import Vue from "vue";
import { Component, Inject, Watch } from "vue-property-decorator";
import ModalTemplate from "../ModalTemplate/ModalTemplate.vue";
import DetailEvent from "./components/DetailEvent/DetailEvent.vue";
import { MastersApiService } from "@/shared/services/mastersApi.service";
import { AppointmentsService } from "@/shared/services/appointments.service";
import moment from "moment";
import { Utils } from "@/shared/utils/Utils";

@Component({
  name: "Calendar",
  components: {
    ModalTemplate,
    DetailEvent,
  },
  computed: {},
})
export default class Calendar extends Vue {
  @Inject() appointmentsService!: AppointmentsService;
  @Inject() mastersApiService!: MastersApiService;

  type = "month";
  filters: CalendarFilters = {
    selectedAppointmentType: null,
    selectedProgram: null,
    selectedDoctor: null,
    date: null,
  };
  listAppointmentTypes = [];
  listPrograms = [];
  listDoctors = [];
  weekday = [1, 2, 3, 4, 5, 6, 0];
  value = "";
  events: CalendarEvent[] = [];
  colors = [
    "blue",
    "indigo",
    "deep-purple",
    "cyan",
    "green",
    "orange",
    "grey darken-1",
  ];
  typeToLabel = {
    month: "Mes",
    week: "Semana",
    day: "Día",
    "4day": "4 Días",
  };
  selectedEvent = {};
  showModalEvent = false;
  keyEventSchedule = 0;
  overlapType = "column";
  intervalHeight = 80;
  updateCalendar = 0;
  intervals = 48;
  intervalDuration = 60;
  intervalIni = 10;

  showConfirmation = false;
  modalTextTemplate = "";

  isLoading = false;

  get duration() {
    return this.intervalDuration;
  }

  get intervalCount() {
    return this.intervals;
  }

  get firstInterval() {
    return this.intervalIni;
  }

  get showScheduleConfirm() {
    return this.showConfirmation;
  }

  set showScheduleConfirm(value) {
    this.showConfirmation = value;
  }

  async mounted() {
    await this.setToday();
    await this.loadPrograms();
  }

  @Watch("filters.selectedProgram")
  async onSelectProgram() {
    this.filters.selectedDoctor = null;
    this.filters.selectedAppointmentType = null;
    if (this.filters.selectedProgram) {
      await this.loadAppointmentTypes(this.filters.selectedProgram);
    }
    await this.loadDoctors();
  }

  @Watch("filters.selectedAppointmentType")
  async onSelectAppointmentType() {
    this.filters.selectedDoctor = null;
    await this.loadDoctors();
  }

  @Watch("filters.selectedDoctor")
  async onSelectDoctor() {
    console.log("cambia doctor");
    await this.getEvents();
  }

  @Watch("filters.date")
  async getEvents() {
    // console.log("this.filkter", this.filters.date);
    this.events = [];
    if (this.type == "month") {
      if (this.filters.date) {
        this.filters.date = moment(this.filters.date)
          .startOf("month")
          .format("YYYY-MM-DD");
      } else {
        this.filters.date = moment().startOf("month").format("YYYY-MM-DD");
      }
    }
    if (
      this.filters.selectedProgram === null ||
      this.filters.selectedProgram === undefined ||
      this.filters.selectedAppointmentType === null ||
      this.filters.selectedAppointmentType === undefined ||
      this.filters.selectedDoctor === null ||
      this.filters.selectedDoctor === undefined ||
      this.filters.date === null ||
      this.filters.date === undefined
    ) {
      return;
    }
    this.events = await this.mapEvents();
  }

  showEvent({ nativeEvent, event }: any) {
    this.selectedEvent = {};
    this.showModalEvent = true;
    this.keyEventSchedule++;
    this.selectedEvent = event;
  }

  viewDay({ date }: any) {
    this.filters.date = date;
    this.type = "day";
  }

  setToday() {
    this.filters.date = "";
  }

  async mapEvents(): Promise<CalendarEvent[]> {
    try {
      let dateToQuery = moment(new Date()).format("YYYY-MM-DD");
      if (this.filters.date != "") {
        dateToQuery = moment(this.filters.date).format("YYYYMMDD");
      }
      let appointments = await this.appointmentsService.getAppointments({
        programId: this.filters.selectedProgram ?? "",
        appointmentTypeId: this.filters.selectedAppointmentType ?? "",
        doctorId: this.filters.selectedDoctor ?? "",
        date: dateToQuery.toString(),
      });
      let eventsMapped: CalendarEvent[] = [];
      // this.intervals = appointments.lenght + 4;
      for (const [index, appointment] of appointments.entries()) {
        const iniEvent = Utils.convertirHora24a12(appointment.hora);
        let dateIniComplete = `${moment(appointment.fecha)
          .format("YYYY-MM-DD")
          .toString()} ${Utils.convertirHora24a12(appointment.hora)}`;
        console.log();
        let dateIniObj = new Date(dateIniComplete);
        // this.intervalDuration = appointment.duracion;
        // this.intervalIni = dateIniObj.getHours() * (60 / this.intervalDuration);

        eventsMapped.push({
          name: iniEvent,
          start: new Date(dateIniObj),
          end: new Date(dateIniObj.getTime() + this.intervalDuration),
          color: "green",
          timed: true,
          // service: "Mamografias",
          // doctor: "Margarita Oviedo",
          // typeService: "Dos",
          state: "Disponible",
          programId: this.filters.selectedProgram ?? "",
          doctor: this.filters.selectedDoctor ?? "",
        });
      }
      return eventsMapped;
    } catch (error) {
      console.log("errror", error);
      return [];
    }
  }

  async loadAppointmentTypes(programId: string) {
    this.isLoading = true;
    this.listAppointmentTypes =
      await this.mastersApiService.getAppointmentTypes(programId);
    this.isLoading = false;
  }

  async loadPrograms() {
    this.isLoading = true;
    this.listPrograms = await this.mastersApiService.getPrograms();
    this.isLoading = false;
  }

  async loadDoctors() {
    if (
      this.filters.selectedProgram === null ||
      this.filters.selectedProgram === undefined ||
      this.filters.selectedAppointmentType === null ||
      this.filters.selectedAppointmentType === undefined
    ) {
      return;
    }
    this.isLoading = true;
    this.listDoctors = await this.mastersApiService.getDoctors(
      this.filters.selectedProgram,
      this.filters.selectedAppointmentType
    );
    this.isLoading = false;
  }

  closeModalAppointment(appointmentData: any) {
    this.showModalEvent = false;
    this.selectedEvent = {};
    if (!appointmentData) {
      return;
    }
    this.modalTextTemplate = `
      Señor(a) ${appointmentData.patientName}, la cita para el servicio de ${
      appointmentData.procedureName
    } ha sido asignada con éxito para el día ${Utils.formatDate(
      appointmentData.date
    )} a las ${
      appointmentData.hour
    }. Debe dirigirse a la sede de Alianza Diagnóstica ubicada en la siguiente dirección: ${
      appointmentData.nameSede
    } - ${appointmentData.addressSede}.
    <br><br>
    Recuerde llegar con 20 minutos de anticipación para trámites administrativos.
    `;
    // console.log(newText);
    // this.modalTextTemplate = `
    // <h4>Usuario agendado correctamente. Verifica los datos de la cita:</h4>
    // <table>
    //   <tr>
    //     <th>Documento:</th>
    //     <td>
    //       ${appointmentData.patientId}
    //     </td>
    //   </tr>
    //   <tr>
    //     <th>Nombre:</th>
    //     <td>${appointmentData.patientName}</td>
    //   </tr>
    //   <tr>
    //     <th>Fecha:</th>
    //     <td>${Utils.formatDate(appointmentData.date)}</td>
    //   </tr>
    //   <tr>
    //     <th>Hora:</th>
    //     <td>${appointmentData.hour}</td>
    //   </tr>
    //   <tr>
    //     <th>Médico:</th>
    //     <td>${appointmentData.doctorName}</td>
    //   </tr>
    //   <tr>
    //     <th>Procedimiento:</th>
    //     <td>${appointmentData.procedureName}</td>
    //   </tr>
    // </table>
    // `;
    this.showScheduleConfirm = true;
  }

  async closeModalConfirmation() {
    this.showScheduleConfirm = false;
    await this.getEvents();
  }
}
