import Vue from "vue";
import { Component, Inject } from "vue-property-decorator";
import Login from "../Login/Login.vue";
import ServiceProvider from "@/providers/ServiceProvider.vue";
import { AuthService } from "../Login/services/AuthService";

@Component({
  name: "App",
  components: {
    ServiceProvider,
    Login,
  },
})
export default class App extends Vue {
  @Inject() authService: AuthService = new AuthService();

  isLogged = false;
  drawer = true;
  value = "recent";
  items = [
    { title: "Agenda", icon: "mdi-view-dashboard", path: "/" },
    { title: "Consultar Citas", icon: "mdi-forum", path: "/appointments" },
    { title: "Crear Paciente", icon: "mdi-account", path: "/create-patient" },
    {
      title: "Tarifas Particulares",
      icon: "mdi-currency-usd",
      path: "/tarifas",
    },
    {
      title: "Médicos",
      icon: "mdi-doctor",
      path: "/doctors",
    },
    // {
    //   title: "Crear/Actualizar Paciente",
    //   icon: "mdi-forum",
    //   path: "/patients",
    // },
  ];

  async signOut() {
    // await this.authService.signOut();
    await this.authService.signOutWithSolin();
    this.$router.replace("/login");
    console.log(this);
  }
}
