import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";
import Home from "@/modules/Home/Home.vue";
import Login from "@/modules/Login/Login.vue";
import Tarifas from "@/modules/Tarifas/Tarifas.vue";
import Appointments from "@/modules/Appointments/Appointments.vue";
import CreatePatient from "@/modules/Patient/Create.vue";
import store from "@/store";
import Doctors from "@/modules/Doctors/Doctors.vue";

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: "/",
    component: Home,
    meta: {
      requiresAuth: true,
    },
    children: [
      // {
      //   path: "/",
      //   name: "Dashboard",
      //   component: Dashboard,
      // },
    ],
  },
  {
    path: "/appointments",
    name: "Appointments",
    component: Appointments,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/create-patient",
    name: "CreatePatient",
    component: CreatePatient,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/tarifas",
    name: "Tarifas",
    component: Tarifas,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/doctors",
    name: "Doctors",
    component: Doctors,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/login",
    name: "Login",
    component: Login,
  },
  {
    path: "*",
    redirect: "/",
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  const userLogged = localStorage.getItem("userLogged") ?? "";
  const userLoggedData = userLogged ? JSON.parse(userLogged) : null;
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (!userLoggedData) {
      next({
        path: "/login",
      });
    } else {
      store.commit("setUserLogged", userLoggedData);
      next();
    }
  } else {
    if (to.path == "/login" && userLoggedData) {
      next({ name: "Home" });
    } else {
      next();
    }
  }
});

export default router;
