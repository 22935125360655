import Vue from "vue";
import { Component } from "vue-property-decorator";
import Calendar from "@/components/Calendar/Calendar.vue";

@Component({
  name: "Home",
  components: {
    Calendar,
  },
  computed: {},
})
export default class Home extends Vue {
  mounted() {
    // this.$store.dispatch("setAlert", {
    //   text: "Hola como estas",
    //   type: "info",
    // });
  }
}

// 3186887408
