import Vue from "vue";
import { Component, Inject, Watch } from "vue-property-decorator";
import Calendar from "@/components/Calendar/Calendar.vue";
import { Utils } from "@/shared/utils/Utils";
import { PatientService } from "@/shared/services/patientService";

@Component({
  name: "Create",
  components: {},
  computed: {},
})
export default class Create extends Vue {
  @Inject() patientService!: PatientService;

  formValid = false;
  patient = {
    pNombre: null,
    sNombre: null,
    pApellido: null,
    sApellido: null,
    documentType: null,
    document: null,
    phone: null,
    cellPhone: null,
    email: null,
    birthdayDate: null,
    gender: null,
    address: null,
    neighborhood: null,
  };

  menu = false;

  rules = {
    required: (value: any) =>
      value === "" ? true : !!value || "Este campo es obligatorio.",
    email: (value: any) => {
      if (value === "") {
        return true;
      }
      const pattern =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return pattern.test(value) || "El correo ingresado no es válido";
    },
    number: (value: any) => {
      if (value === "") {
        return true;
      }
      const pattern = /^\d+$/;
      return pattern.test(value) || "Sólo se permiten números";
    },
  };

  documentTypes = [{ id: "CC", name: "Cédula" }];

  genders = [
    { id: "M", name: "MASCULINO" },
    { id: "F", name: "FEMENINO" },
    { id: "N", name: "NO APLICA" },
  ];

  todayFormated = "";
  mounted() {
    this.resetPatient();
    this.todayFormated = Utils.todayFormated();
  }

  resetPatient() {
    this.patient = {
      pNombre: null,
      sNombre: null,
      pApellido: null,
      sApellido: null,
      documentType: null,
      document: null,
      phone: null,
      cellPhone: null,
      email: null,
      birthdayDate: null,
      gender: null,
      address: null,
      neighborhood: null,
    };
  }

  async savePatient() {
    const responseData = await this.patientService.createPatient(this.patient);
    if (responseData.error) {
      this.$store.dispatch("setAlert", {
        text: responseData.message,
        type: "warning",
      });
    } else {
      await this.$store.dispatch("setAlert", {
        text: responseData.message,
        type: "success",
      });
      this.resetPatient();
    }
  }
}
