import Vue from "vue";
import { Component, Provide } from "vue-property-decorator";
import { AuthService } from "@/modules/Login/services/AuthService";
import { MastersApiService } from "@/shared/services/mastersApi.service";
import { AppointmentsService } from "@/shared/services/appointments.service";
import { PatientService } from "@/shared/services/patientService";

@Component({
  name: "ServiceProvider",
})
export default class ServiceProvider extends Vue {
  @Provide()
  authService: AuthService = new AuthService();

  @Provide()
  mastersApiService: MastersApiService = new MastersApiService();

  @Provide()
  appointmentsService: AppointmentsService = new AppointmentsService();

  @Provide()
  patientService: PatientService = new PatientService();
}
