import apiClient from "@/shared/Http/Http.service";
// import firebase from "../../Firebase/FirebaseService";
import store from "@/store";

export class AuthService {
  // async signInByEmailAndPassword(
  //   email: string,
  //   password: string
  // ): Promise<UserLogged | boolean> {
  //   return new Promise((resolve, reject) => {
  //     firebase
  //       .auth()
  //       .signInWithEmailAndPassword(email, password)
  //       .then(async () => {
  //         resolve(true);
  //       })
  //       .catch((err) => {
  //         reject(err.message);
  //       });
  //   });
  // }

  // signOut() {
  //   return firebase.auth().signOut();
  // }

  async signWithSolin(user: string, password: string) {
    // eslint-disable-next-line
    return new Promise(async (resolve, reject) => {
      const response = await apiClient.post("/login/solin", {
        user,
        password,
      });
      if (response.data.length == 0) {
        reject(false);
      } else {
        store.commit("setUserLogged", {
          uid: response.data[0].Cedulausuario,
          displayName: response.data[0].Nombreusuario,
        });
        resolve(true);
      }
    });
  }

  async signOutWithSolin(): Promise<UserLogged | boolean> {
    return new Promise((resolve, reject) => {
      store.commit("setCloseSession");
      resolve(true);
    });
  }
}
