import axios from "axios";

const apiClient = axios.create({
  baseURL: "https://api-solinweb-aqvujz7gjsnm8.alianzadx.com/solin-api/",
  headers: {
    "content-type": "application/json",
    Accept: "application/json",
    Authorization: "@li4nz4",
  },
});

export default apiClient;
